const lore = {
  achiever: {
    title: 'Welcome, Achiever.',
    par1: 'You’re here because you want more: more energy, sharper focus, and peak performance. You’re not here to maintain - you’re here to thrive. Whether refining habits or mastering mindset, let’s unlock your potential with actionable insights.',
    par2: 'Go beyond the norm <a href="https://wellawarecare.substack.com/p/be-better-than-the-conventional-normal" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Be better than the conventional normal)</a> and discover your ideal vitamin D and blood glucose levels. Turn sleep into a performance asset <a href="https://wellawarecare.substack.com/p/high-performance-sleeping" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(High performance sleeping)</a>, protect your brain <a href="https://wellawarecare.substack.com/p/brain-health-101" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Brain health 101)</a>, and understand insulin resistance - from energy and inflammation to mindset <a href="https://wellawarecare.substack.com/p/care-4-invisible-insulin" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Invisible Insidious Insulin)</a>.',
    par3: 'Explore sources and subscribe to the weekly newsletter.',
    par4: 'Now, get after it!',
  },
  fighter: {
    title: 'Hello, Fighter.',
    par1: 'Life has thrown you a challenge, but you’re ready to face it head-on. Whether managing a condition or overcoming health setbacks, this is your space to regain control and find strength. Discover resources that offer clarity and hope.',
    par2: 'Whatever you’re fighting, you’ll need energy. Learn about mitochondria - what harms them and what strengthens them <a href="https://wellawarecare.substack.com/p/your-energy-source" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Your energy source)</a>. Take a holistic approach <a href="https://wellawarecare.substack.com/p/care-11-functional-medicine-functioning" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Functional medicine functioning)</a> that sees the body as an interconnected system, not isolated parts. Explore foods that hinder your fight <a href="https://wellawarecare.substack.com/p/non-food-foods" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Non-food foods)</a>.',
    par3: 'Explore sources and subscribe to the weekly newsletter.',
    par4: 'Don’t sweat it - you’ve got loads of ammo!',
  },
  overwhelmed: {
    title: 'Hi there, Overwhelmed.',
    par1: 'If managing your health feels like solving a puzzle without the pieces, it is. This space makes health advice practical and manageable. Start small, build confidence, gain clarity, and dive deeper with the sources provided.',
    par2: 'Begin with “you are what you eat” and add “… and the supplements you take.” Explore the 80-20 of organics <a href="https://wellawarecare.substack.com/p/care-12-is-organic-food-worth-it" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Is organic food worth it?)</a>, whether supplements help or just create expensive urine <a href="https://wellawarecare.substack.com/p/supplementation-isnot-expensive-urine" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Supplementation is/not expensive urine)</a>, and the benefits of not eating <a href="https://wellawarecare.substack.com/p/less-eating-is-more-healthy" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Less (eating) is more (healthy))</a>. Understanding what goes into your body - and when - lays the foundation for navigating your health.',
    par3: 'Explore sources and subscribe to the weekly newsletter.',
    par4: 'It’s not complicated- just interconnected!',
  },
  curious: {
    title: 'Greetings, Curious.',
    par1: 'You’re fascinated by how health connects - sleep, nutrition, stress, and more. If uncovering the ‘why’ behind wellness and finding actionable links excites you, this space is yours.',
    par2: 'Start with functional medicine <a href="https://wellawarecare.substack.com/p/care-11-functional-medicine-functioning" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Functional medicine functioning)</a>, which treats the body as an interconnected system, factoring in biology, environment, and mind. Dive into fasting’s history <a href="https://wellawarecare.substack.com/p/less-eating-is-more-healthy" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Less (eating) is more (healthy))</a> and its ties to metabolism, cognition, cellular health, and immunity. Learn how sleep <a href="https://wellawarecare.substack.com/p/high-performance-sleeping" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(High performance sleeping)</a> supports brain detox, emotional processing, and hormone balance.',
    par3: 'Explore sources and subscribe to the weekly newsletter.',
    par4: 'This is only the start. Welcome to the rabbit hole!',
  },
  protector: {
    title: 'Welcome, Protector.',
    par1: 'You value what you have and are committed to preserving it - your energy, health, and well-being. This space offers proactive strategies to safeguard your vitality now and for years to come.',
    par2: 'Understand the power of potassium, vitamin D, blood glucose, and creatine <a href="https://wellawarecare.substack.com/p/hold-on-to-your-strength" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Hold on to your strength)</a>. Learn how nutrition, supplements, and lifestyle choices help prevent Alzheimer’s <a href="https://wellawarecare.substack.com/p/preventing-alzheimers-with-lifestyle" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Preventing Alzheimer’s with lifestyle choices)</a>. Know that inflammation triggers health decline <a href="https://wellawarecare.substack.com/p/inflamation-to-cancer" class="nav-link lore-link" id="lore-link-mobile" target="_blank">(Inflammation to cancer)</a>, and take steps to reduce it and support your immune system.',
    par3: 'Explore sources and subscribe to the weekly newsletter.',
    par4: 'You’re already protecting what matters - build on it!',
  },
};

export default lore;
