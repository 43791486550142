import achiever from './imgs/achiever.png';
import busy from './imgs/busy.png';
import curious from './imgs/curious.png';
import fighter from './imgs/fighter.png';
import myJourney from './imgs/myJourney.png';
import nobullshit from './imgs/nobullshit.png';
import optimiser from './imgs/optimiser.png';
import overwhelmed from './imgs/overwhelmed.png';
import protector from './imgs/protector.png';
import unlock from './imgs/unlock.png';
import yourJourney from './imgs/yourJourney.png';
import funcMed from './imgs/funcMed.png';
import caffeineNeverSleeps from './imgs/caffeineNeverSleeps.png';
import sleepDreamReap from './imgs/sleepDreamReap.png';
import orgFood from './imgs/orgFood.png';
import fruitJuice from './imgs/fruitJuice.png';
import lore from './lore';

// ELEMENTS SELECTION

// Elements for sticky navigation
const header = document.querySelector('.header');
const heroBoxEl = document.querySelector('.hero-box');
// Elements for lazy loading images

// Mobile navigation menu
const btnNavEl = document.querySelector('.btn-mobile-nav');
const navContainerEl = document.querySelector('.nav-container');
const heroContainerEl = document.querySelector('.hero-container');
const sentinel = document.querySelector('#nav-sentinel');

// Elements for the persona section
const personaBoxElements = document.querySelectorAll('.persona-box');
const personaTitle = document.querySelector('.persona-title');
const personaText = document.querySelector('.persona-text');

const personeLoreTitleEl = document.querySelector('.persona-lore-title');
const personaLorePar1El = document.querySelector('.persona-lore-par-1');
const personaLorePar2El = document.querySelector('.persona-lore-par-2');
const personaLorePar3El = document.querySelector('.persona-lore-par-3');
const personaLorePar4El = document.querySelector('.persona-lore-par-4');
const personaImgEl = document.querySelector('.persona-img');

// Elements for the persona section mobile
const personaContainersMobile = document.querySelectorAll(
  '.persona-container-mobile'
);
const personaImgMobile = document.querySelector('.persona-img-mobile');
const personaTextMobile = document.querySelector('.persona-text-mobile');
const personaTitleMobile = document.querySelector('.persona-title-mobile');
const loreContainerMobile = document.querySelector('.lore-container-mobile');
const loreTextContainerMobile = document.querySelector('.lore-text-box-mobile');

// Image obj

const imagesObj = {
  'imgs/achiever.png': achiever,
  'imgs/busy.png': busy,
  'imgs/curious.png': curious,
  'imgs/fighter.png': fighter,
  'imgs/myJourney.png': myJourney,
  'imgs/nobullshit.png': nobullshit,
  'imgs/optimiser.png': optimiser,
  'imgs/overwhelmed.png': overwhelmed,
  'imgs/protector.png': protector,
  'imgs/unlock.png': unlock,
  'imgs/yourJourney.png': yourJourney,
  'imgs/funcMed.png': funcMed,
  'imgs/caffeineNeverSleeps.png': caffeineNeverSleeps,
  'imgs/sleepDreamReap.png': sleepDreamReap,
  'imgs/orgFood.png': orgFood,
  'imgs/fruitJuice.png': fruitJuice,
};

// STICKY NAVIGATION

const stickyNav = function (entries) {
  const [entry] = entries;

  if (!entry.isIntersecting) {
    navContainerEl.classList.add('sticky');
  } else {
    navContainerEl.classList.remove('sticky');
  }
};

const headerObserver = new IntersectionObserver(stickyNav, {
  root: null,
  threshold: 0,
  rootMargin: '-1px 0px 0px 0px', // Trigger as soon as the sentinel exits the viewport
});

headerObserver.observe(sentinel);

// Menu scrolling

let headerHeight = window.innerHeight * 0.1;
let lastScrollTop = 0;

window.addEventListener('scroll', function () {
  let scrollTop = window.scrollY || this.document.documentElement.scrollTop;

  if (scrollTop > lastScrollTop && scrollTop > headerHeight) {
    // scrolling down beyond header
    navContainerEl.classList.add('hidden-mobile');
  } else {
    navContainerEl.classList.remove('hidden-mobile');
  }

  lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
});

// Lazy loading images
const lazyLoadImages = function () {
  const lazyImages = document.querySelectorAll('img[data-src]');

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  const imageObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;

        // TODO: clear images that are not required
        if (lazyImage.dataset.src) {
          // Store imported HQ images in an object, so the link does not get broken

          // Identifying data-src for each image
          const originalDataSrc = lazyImage.getAttribute('data-src');
          // Attributing the right image using the original data-src
          lazyImage.src = imagesObj[originalDataSrc];

          lazyImage.removeAttribute('data-src');
          lazyImage.classList.remove('lazy-img');
          observer.unobserve(lazyImage);
        }
      }
    });
  }, observerOptions);

  lazyImages.forEach(lazyImage => imageObserver.observe(lazyImage));
};

lazyLoadImages();

// Function to handle the intersection
const handleIntersectionImg = function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
    }
  });
};

// Lazy loading video

document.addEventListener('DOMContentLoaded', function () {
  const videoContainers = document.querySelectorAll('.video-container');

  function handleIntersection(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const img = entry.target.querySelector('img');
        const videoUrl = img.getAttribute('data-video');
        const video = document.createElement('video');

        video.src = videoUrl;
        video.controls = false;
        video.autoplay = true;
        video.loop = true;
        video.muted = true;
        video.playsInline = true;
        video.preload = 'auto';
        video.classList.add('video');

        img.parentNode.replaceChild(video, img);
        observer.unobserve(entry.target);
      }
    });
  }

  const observer = new IntersectionObserver(handleIntersection, {
    rootMargin: '0px',
    threshold: 0.1,
  });

  videoContainers.forEach(container => {
    observer.observe(container);
  });
});

// CAROUSEL
/*
const slider = function () {
  const slides = document.querySelectorAll('.slide');
  const btnLeft = document.querySelector('.slider__btn--left');
  const btnRight = document.querySelector('.slider__btn--right');
  let currentSlide = 0;
  const maxSlide = slides.length;
  const dotContainer = document.querySelector('.dots');

  // Functions
  const createDots = function () {
    slides.forEach(function (_, i) {
      dotContainer.insertAdjacentHTML(
        'beforeend',
        `<button class = "dots__dot dot" data-slide="${i}"></button>`
      );
    });
  };

  const activateDot = function (slide) {
    document
      .querySelectorAll('.dots__dot')
      .forEach(dot => dot.classList.remove('dots__dot--active'));

    document
      .querySelector(`.dots__dot[data-slide="${slide}"]`)
      .classList.add('dots__dot--active');
  };

  const goToSlide = function (slide) {
    slides.forEach(
      (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
    );
  };

  const nextSlide = function () {
    if (currentSlide === maxSlide - 1) {
      currentSlide = 0;
    } else {
      currentSlide++;
    }

    goToSlide(currentSlide);
    activateDot(currentSlide);
  };

  const init = function () {
    goToSlide(0);
    createDots();
    activateDot(0);
  };
  init();

  // Event handlers
  btnRight.addEventListener('click', nextSlide);

  const prevSlide = function () {
    if (currentSlide === 0) {
      currentSlide = maxSlide - 1;
    } else {
      currentSlide--;
    }
    goToSlide(currentSlide);
    activateDot(currentSlide);
  };

  btnLeft.addEventListener('click', prevSlide);

  document.addEventListener('keydown', function (e) {
    if (e.key === 'ArrowLeft') prevSlide();
    e.key === 'ArrowRight' && nextSlide();
  });

  dotContainer.addEventListener('click', function (e) {
    if (e.target.classList.contains('dots__dot')) {
      console.log('DOT');
      const { slide } = e.target.dataset;
      console.log(e.target);
      goToSlide(slide);
      activateDot(slide);
    }
  });
};
slider();
*/

// Mobile navigation menu

btnNavEl.addEventListener('click', function () {
  navContainerEl.classList.toggle('nav-open');
});

const navLinksEl = document.querySelector('.nav-links');
const navLinkEl = document.querySelectorAll('.nav-link');

navLinksEl.addEventListener('click', function (e) {
  let isClickedInsideNavLinks = false;
  navLinkEl.forEach(link => {
    if (link.contains(e.target)) {
      isClickedInsideNavLinks = true;
    }
  });

  if (!isClickedInsideNavLinks) {
    navContainerEl.classList.toggle('nav-open');
    heroContainerEl.classList.toggle('nav-open');
  }
});

// Lore UX for the personas

const updateLoreBox = function (personaBox) {
  const persona = personaBox.dataset.persona;
  const imgUrl = `imgs/${persona}.png`;

  // Update mobile version
  document.querySelector(
    '.lore-text-box-mobile .persona-lore-title'
  ).innerHTML = lore[persona].title;
  document.querySelector(
    '.lore-text-box-mobile .persona-lore-par-1'
  ).innerHTML = lore[persona].par1;
  document.querySelector(
    '.lore-text-box-mobile .persona-lore-par-2'
  ).innerHTML = lore[persona].par2;
  document.querySelector(
    '.lore-text-box-mobile .persona-lore-par-3'
  ).innerHTML = lore[persona].par3;
  document.querySelector(
    '.lore-text-box-mobile .persona-lore-par-4'
  ).innerHTML = lore[persona].par4;

  // Update desktop version
  document.querySelector('.lore-text-box-desktop .persona-img').src =
    imagesObj[imgUrl];
  document.querySelector(
    '.lore-text-box-desktop .persona-lore-title'
  ).innerHTML = lore[persona].title;
  document.querySelector(
    '.lore-text-box-desktop .persona-lore-par-1'
  ).innerHTML = lore[persona].par1;
  document.querySelector(
    '.lore-text-box-desktop .persona-lore-par-2'
  ).innerHTML = lore[persona].par2;
  document.querySelector(
    '.lore-text-box-desktop .persona-lore-par-3'
  ).innerHTML = lore[persona].par3;
  document.querySelector(
    '.lore-text-box-desktop .persona-lore-par-4'
  ).innerHTML = lore[persona].par4;
};

// Active status for the first persona
const firstPersona = personaBoxElements[0];
firstPersona.classList.add('active');
updateLoreBox(firstPersona);

// Event listener for the persona boxes
const handlePersonaInteraction = e => {
  const personaBox = e.target.closest('.persona-box');
  if (!personaBox) return;

  // Remove the active class from all boxes
  personaBoxElements.forEach(persona => persona.classList.remove('active'));

  // Add active class to the current box
  const currentBox = e.currentTarget;
  currentBox.classList.add('active');

  // Update the lore box content
  updateLoreBox(personaBox);
};

personaBoxElements.forEach(box => {
  // Attach for desktop (mouse enter)
  box.addEventListener('mouseenter', handlePersonaInteraction);

  // Attach for tablets and touch devices (click)
  box.addEventListener('click', handlePersonaInteraction);
});

// Personas mobile
personaContainersMobile.forEach(container => {
  container.addEventListener('click', e => {
    const personaContainer = e.target.closest('.persona-container-mobile');
    if (!personaContainer) return;

    loreTextContainerMobile.classList.toggle('active');

    updateLoreBox(personaContainer);
  });
});

loreTextContainerMobile.addEventListener('click', e => {
  loreTextContainerMobile.classList.toggle('active');
});

document.querySelector('.close-overlay').addEventListener('click', e => {
  e.stopPropagation();
  loreTextContainerMobile.classList.remove('active');
});

function toggleSectionsBasedOnScreenSize() {
  const mobileSection = document.querySelector('.personas-section-mobile');
  const desktopSection = document.querySelector('.personas-section-desktop');

  if (window.innerWidth < 768) {
    // Adjust the breakpoint as needed
    mobileSection.classList.remove('hidden');
    desktopSection.classList.add('hidden');
  } else {
    mobileSection.classList.add('hidden');
    desktopSection.classList.remove('hidden');
  }
}

// Check when the page loads
document.addEventListener('DOMContentLoaded', toggleSectionsBasedOnScreenSize);

// Check when the window is resized
window.addEventListener('resize', toggleSectionsBasedOnScreenSize);
